import {
  forwardRef,
  memo,
  MutableRefObject,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import styles from './AccordionItem.module.scss';

import AccordionTrigger from '@/components/AccordionTrigger/AccordionTrigger';

import sanitizer from '@/utils/sanitizer';

export type Props = {
  className?: string;
  isInitiallyOpen?: boolean;
  indexItem?: string;
  title: string;
  description?: string;
  body: string | ReactElement[];
  selectedNumber?: number;
  delay?: number;
  animateScroll?: boolean;
};
export type AccordionHandle = {
  animateIn: () => GSAPTimeline;
  animateOut: () => GSAPTimeline;
};

function AccordionItem(
  {
    className,
    isInitiallyOpen = false,
    indexItem,
    title,
    description,
    body,
    delay = 0,
    selectedNumber,
    animateScroll = true
  }: Props,
  ref: Ref<AccordionHandle>
) {
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const shouldAnimate = useRef(false);
  const hasList = typeof body !== 'string';
  const itemTL = useRef() as MutableRefObject<GSAPTimeline>;
  const itemExpandTL = useRef() as MutableRefObject<GSAPTimeline>;

  const onReverse = (func: { (scale: number): void }, onlyAfterComplete: boolean) => {
    let time = 0,
      reversed = false;
    return () => {
      let t = itemTL.current.time(),
        r = t < time;
      r && !reversed && (!onlyAfterComplete || time === itemTL.current.duration()) && func(1.6);
      time = t;
      reversed = r;
    };
  };

  function animateOutExpand(scale: number) {
    itemExpandTL.current.timeScale(scale).reverse();
  }

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  useEffect(() => {
    const duration = shouldAnimate.current ? 1 : 0;
    if (itemExpandTL.current) {
      isOpen
        ? itemExpandTL.current.timeScale(duration).play()
        : itemExpandTL.current.timeScale(1.3).reverse();
    }

    shouldAnimate.current = true;
  }, [isOpen, shouldAnimate]);

  useEffect(() => {
    setIsOpen(isInitiallyOpen);
  }, [isInitiallyOpen]);

  useImperativeHandle(ref, () => ({
    animateIn: () => itemTL.current.play(),
    animateOut: () => itemTL.current.reverse()
  }));
  useEffect(() => {
    const q = gsap.utils.selector(itemRef);
    itemTL.current = gsap
      .timeline({
        delay: delay,
        paused: true,
        onUpdate: onReverse(animateOutExpand, true)
      })
      .fadeInFrom(q('.accordionItemTitle'))
      .from(q('.extraField'), { autoAlpha: 0, duration: 0.5, ease: 'linear' }, '-=0.468')
      .expandLine(q('.underline'), { duration: animateScroll ? 1 : 0.5 }, '-=0.468');

    itemExpandTL.current = gsap
      .timeline({
        paused: true
      })

      .set(collapsibleRef.current, { height: 'auto', autoAlpha: 1 })
      .from(collapsibleRef.current, {
        height: 0,
        autoAlpha: 0,
        duration: 0.6,
        overwrite: true,
        ease: 'ease1'
      })

      .from(
        collapsibleRef.current!.children,
        {
          autoAlpha: 0,
          stagger: 0.037,
          duration: 0.368,
          ease: 'linear'
        },
        '-=0.57'
      );

    animateScroll &&
      setTimeout(() => {
        ScrollTrigger.create({
          trigger: itemRef.current,
          start: 'top 70%',
          end: 'bottom top',
          once: true,
          onEnter: () => {
            itemTL.current.play();
            isInitiallyOpen && itemExpandTL.current.play();
          }
        });
      }, 200);
  }, [delay, shouldAnimate, animateScroll, isInitiallyOpen]);

  return (
    <div
      ref={itemRef}
      className={classnames(styles.AccordionItem, className, {
        [styles.isInitiallyOpen]: isInitiallyOpen,
        [styles.hasList]: hasList
      })}
    >
      {indexItem && <span className={classnames(styles.index, 'extraField')}>{indexItem}</span>}
      <h3 className={classnames(styles.title, 'accordionItemTitle')}>{title}</h3>
      {description && <p className={classnames(styles.description, 'extraField')}>{description}</p>}
      <div className={classnames(styles.btnContainer, 'extraField')} onClick={handleToggle}>
        {selectedNumber && hasList ? (
          <span className={styles.selectedNumber}>
            {selectedNumber > 0 && selectedNumber + ' selected'}
          </span>
        ) : null}

        <AccordionTrigger isOpen={isOpen} hasDropdownSvg={hasList} />
      </div>
      {!hasList ? (
        <div
          className={styles.content}
          ref={collapsibleRef}
          dangerouslySetInnerHTML={{ __html: sanitizer(body ?? '') }}
        />
      ) : (
        <div className={styles.bodyList} ref={collapsibleRef}>
          {body.map((a) => {
            return a;
          })}
        </div>
      )}

      <span className={classnames(styles.underline, 'underline')} />
    </div>
  );
}

export default memo(forwardRef(AccordionItem));
