import { memo } from 'react';

import Blocks from '@/blocks/Blocks/Blocks';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup';

import { Block } from '@/client';

export type Props = {
  innerBlocks?: Block[];
  layout: {
    justifyContent?: 'left' | 'center' | 'right';
    orientation?: 'horizontal' | 'vertical';
  };
};

function BlockButtons({ innerBlocks, layout }: Props) {
  return (
    <ButtonGroup {...layout}>
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </ButtonGroup>
  );
}

export default memo(BlockButtons);
